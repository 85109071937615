@import "/styles/variables";

@font-face {
  font-family: "Canva Sans";
  font-style: normal;
  font-display: swap;
  src:
    local("CanvaSans-Medium"),
    url("#{staticPath('/fonts/CanvaSans-Medium.woff2')}") format("woff2"),
    url("#{staticPath('/fonts/CanvaSans-Medium.woff')}") format("woff");
}

@font-face {
  font-family: "Canva Sans";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    local("CanvaSans-Bold"),
    url("#{staticPath('/fonts/CanvaSans-Bold.woff2')}") format("woff2"),
    url("#{staticPath('/fonts/CanvaSans-Bold.woff')}") format("woff");
}

// better to change weight centrall here than to add this in all text component:
// <Text size="h$33" weight="bold"/>
@font-face {
  font-family: "Canva Sans Bold";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    local("CanvaSans-Medium"),
    url("#{staticPath('/fonts/CanvaSans-Medium.woff2')}") format("woff2"),
    url("#{staticPath('/fonts/CanvaSans-Medium.woff')}") format("woff");
}
