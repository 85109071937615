@use "sass:list";

@import "/styles/variables";

$spaces: 4, 8, 12, 15, 20, 24, 30, 32, 50, 80;
$media-size-prefixes: "m", "t", "d", "o";

@layer spacing {
  .noMargin {
    margin: 0;
  }

  @each $space in $spaces {
    .mt#{$space} {
      margin-top: #{$space}px;
    }

    .mr#{$space} {
      margin-right: #{$space}px;
    }

    .mb#{$space} {
      margin-bottom: #{$space}px;
    }

    .ml#{$space} {
      margin-left: #{$space}px;
    }

    .pt#{$space} {
      padding-top: #{$space}px;
    }

    .pr#{$space} {
      padding-right: #{$space}px;
    }

    .pb#{$space} {
      padding-bottom: #{$space}px;
    }

    .pl#{$space} {
      padding-left: #{$space}px;
    }

    @for $i from 1 through length($media-ordered-sizes) {
      $breakpoint: list.nth($media-ordered-sizes, $i);
      $prefix: list.nth($media-size-prefixes, $i);

      @include media($breakpoint) {
        .#{$prefix}mt#{$space} {
          margin-top: #{$space}px;
        }

        .#{$prefix}mr#{$space} {
          margin-right: #{$space}px;
        }

        .#{$prefix}mb#{$space} {
          margin-bottom: #{$space}px;
        }

        .#{$prefix}ml#{$space} {
          margin-left: #{$space}px;
        }

        .#{$prefix}pt#{$space} {
          padding-top: #{$space}px;
        }

        .#{$prefix}pr#{$space} {
          padding-right: #{$space}px;
        }

        .#{$prefix}pb#{$space} {
          padding-bottom: #{$space}px;
        }

        .#{$prefix}pl#{$space} {
          padding-left: #{$space}px;
        }
      }
    }
  }
}
