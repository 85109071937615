@import "/styles/variables";

.carousel {
  &.carousel-slider {
    overflow: visible !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .control-dots {
    display: flex;

    width: auto !important;
    margin-bottom: $space-20 !important;
    padding: $space-8 6px !important;

    background: rgba($color-black, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 50px;

    .dot.dot {
      margin: 0 3px;
      background: $color-white;
      box-shadow: none;
    }

    .dot:not(.selected) {
      opacity: 0.5 !important;
    }

    .dot.selected {
      opacity: 1 !important;
    }
  }
}
