@import "/styles/variables";

.banner {
  @include margin-grid-responsive;

  position: relative;
  padding-top: $space-20;
  padding-bottom: $space-20;
  background: $color-black;
}

.close {
  position: absolute;
  top: $space-20;
  @include margin-grid-responsive-property("right");

  width: 24px;
  height: 24px;
  padding: 0;

  background: none;

  // Button reset
  border: none;

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
