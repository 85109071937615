@import "/styles/variables";

.alertContainer {
  position: sticky;
  z-index: $z-index-generic-modal + 1;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 600px;
  margin-right: auto;
  margin-left: auto;

  &.hide {
    display: none;
  }

  .innerContainer {
    width: 100%;
    padding-right: $space-8;
    padding-left: $space-8;
  }
}

.alert {
  @include media("mobile") {
    flex-direction: column;
  }

  min-height: 50px;
  border-radius: 10px;
}

.theme-greyMinus3F7F7F7 {
  background: $color-greyMinus3;
}

.theme-greyMinus2DFDFE0 {
  background: $color-greyMinus2;
}

.theme-greyMinus1BFBFBF {
  background: $color-greyMinus1;
}

.theme-grey7F7F7F {
  background: $color-grey;
}

.theme-greyPlus14A4A4A {
  background: $color-greyPlus1;
}

.theme-midnight2C343E {
  background: $color-midnight;
}

.theme-black000000 {
  background: $color-black;
}

.theme-whiteFFFFFF {
  background: $color-white;
}

.theme-green05A081 {
  background: $color-green;
}

.theme-redD3405C {
  background: $color-red;
}

.theme-purple7831ED {
  background: $color-purple;
}

.theme-yellowFFC864 {
  background: $color-yellow;
}
