@use "sass:color";

@import "/styles/variables";

$modal-border-radius: 15px;
$upper-content-offset: 30px;
$padding: $space-15;

// The opaque black overlay
.overlay {
  position: fixed;
  z-index: $z-index-generic-modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: color.change($color-black, $alpha: 0.9);

  &.positionContentBottom {
    justify-content: flex-end;
  }

  &.positionContentCenter {
    justify-content: center;
  }

  &.positionContentTop {
    justify-content: flex-start;
  }

  &.overlayDim {
    background-color: rgb(0 0 0 / 40%);
  }
}

.scrollWrapper {
  width: 100%;
  max-height: 100vh;

  // Fix for mobile safari bug where 100vh doesn't account for the bottom edge of the browser viewport
  max-height: stretch;
  outline: none;

  .positionContentBottom & {
    @include media("mobile", "tablet") {
      max-height: 90vh;
    }

    .contentPaddingWrapper:last-of-type {
      padding-bottom: $padding + $space-30;
    }
  }

  .positionContentTop & {
    .contentPaddingWrapper:first-of-type {
      @include media("mobile") {
        padding-top: $space-50;
      }

      padding-top: $space-20;
    }
  }

  &.overlaySpaceAbove .contentPaddingWrapper:first-of-type {
    padding-top: $space-50;
  }
}

.contentPaddingWrapper {
  padding: $padding * 0.5;
}

.content {
  position: relative;
  background: $color-white;
  border-radius: $modal-border-radius;

  &.hideBackground {
    background: transparent;
  }
}

.contentAboveSwitchLocaleModal {
  .contentWrapper {
    margin-bottom: 50px;
  }
}

.contentOverflowHidden {
  overflow: hidden;
}

.upperContent {
  z-index: -1;
  transform: translateY(#{$upper-content-offset * 0.5});

  width: 100%;
  padding-bottom: $upper-content-offset;

  color: $color-white;

  background: $color-black;
  border-top-left-radius: $modal-border-radius;
  border-top-right-radius: $modal-border-radius;
}

.hasUpperContent {
  .content {
    transform: translateY(-#{$upper-content-offset * 0.5});
  }
}

.close {
  @include media("mobile", "tablet") {
    top: $space-15;
    right: $space-15;
  }

  cursor: pointer;

  position: absolute;
  top: $space-20;
  right: $space-20;

  width: 20px;
  height: 20px;
  padding: 0;

  background: none;
  border: none;

  transition: opacity linear 0.2s;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  &.inOverlay {
    top: -$space-50;
    right: 0;
  }

  &.fixed {
    @include media("tablet") {
      left: $space-30;
    }

    @include media("mobile") {
      top: $space-20;
      left: $space-20;
    }

    position: fixed;
    top: $space-50;
    right: auto;
    left: $space-80;
  }

  &.small {
    width: 14px;
    height: 14px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.square {
  .content {
    border-radius: 0;
  }

  .upperContent {
    border-radius: 0;
  }
}

.squareMobile {
  @include media("mobile") {
    .content {
      border-radius: 0;
    }

    .upperContent {
      border-radius: 0;
    }
  }
}

.squareTablet {
  @include media("tablet") {
    .content {
      border-radius: 0;
    }

    .upperContent {
      border-radius: 0;
    }
  }
}

.squareDesktop {
  @include media("desktop") {
    .content {
      border-radius: 0;
    }

    .upperContent {
      border-radius: 0;
    }
  }
}

.squareOversized {
  @include media("oversized") {
    .content {
      border-radius: 0;
    }

    .upperContent {
      border-radius: 0;
    }
  }
}

.close.rightTopBtn {
  top: -32px;
  right: 0;
}

.steppedModalIndicators {
  position: absolute;
  z-index: 1;
  bottom: -$space-30;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  .steppedModalIndicator {
    width: 8px;
    height: 8px;
    margin: 0 $space-4;
    padding: 0;

    appearance: none;
    opacity: 0.4;
    background-color: $color-greyscale-white;
    border: 0;
    border-radius: 50%;

    &.active {
      opacity: 1;
    }

    &:not(:disabled):focus-visible {
      outline: 2px solid $color-jellyfish-dark;
    }

    &.clickable {
      cursor: pointer;
    }
  }
}
