@import "/styles/variables";
@import "/shared/ui/Dropdowns/dropdown.module";
@import "/shared/ui/Dropdowns/option.module";

.pac-container {
  @include menu;

  z-index: $z-index-generic-modal;
  display: block;
  margin-top: $space-8;
  font-family: inherit;

  &::after {
    display: none;
  }

  .pac-item {
    @include option;

    display: block;

    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 26px;
    color: $color-greyPlus1;
    white-space: normal;

    .pac-icon {
      display: none;
    }

    .pac-item-query {
      padding-right: 5px;
      font-size: inherit;
      color: inherit;
    }

    .pac-matched {
      font-weight: inherit;
    }
  }

  .pac-item-selected {
    background: $color-greyMinus3;
  }
}
