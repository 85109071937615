.radiusContainer {
  overflow: hidden;
  line-height: 0;
  border-radius: 15px;
}

/* stylelint-disable selector-class-pattern -- Outside our control */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* stylelint-enable */
