@use "sass:color";

@import "/styles/variables";

// Prepare yourself for a million !important overrides 🙈
// This code makes the onetouch gdpr compliance banners adhere to our UI kit
// It is ugly because their css was not easy to override, but should be safe since they won't update ids
// If we update versions of onetouch we will need to double check to make sure nothing changes.

body {
  // numbered headers map
  $numberMap: (
    1: 4.1,
    2: 4.2,
    3: 4.3,
    4: 4.4,
    5: 4.5,
    6: 4.6,
    7: 4.7,
  );

  %button-override {
    @include media("mobile") {
      height: 40px !important;
      font-size: 16px !important;
    }

    cursor: pointer !important;

    height: 50px !important;
    padding: 0 20px !important;

    font-size: 16px !important;
    font-weight: $font-weight-semibold !important;
    -webkit-font-smoothing: antialiased !important;
    line-height: 1 !important;
    color: $color-white;
    letter-spacing: -0.015em !important;
    white-space: nowrap !important;

    background: $color-green !important;
    border: 1px solid $color-green !important;
    border-radius: 6px !important;
    outline: none !important;

    transition:
      0.1s background-color,
      border ease-in-out,
      0.1s transform,
      0.005s box-shadow,
      0.25s border-color !important;

    &:hover {
      color: $color-white;
      background-color: color.mix($color-green, $color-black, 92%) !important;
      border-color: $color-green !important;
    }

    &:focus,
    &:active {
      color: $color-white;
      background: color.mix($color-green, $color-black, 85%) !important;
      border-color: $color-green !important;
    }
  }

  %button-with-no-background {
    color: $color-greyPlus1 !important;
    background: none !important;
    border-color: $color-white !important;

    &:focus,
    &:active {
      color: $color-white;
      background: none !important;
      border-color: $color-white !important;
    }

    &:hover {
      color: $color-green !important;
      background: none !important;
      border-color: $color-white !important;
    }
  }

  #ot-sdk-btn.ot-sdk-show-settings,
  #ot-sdk-btn.optanon-show-settings {
    @extend %button-override;

    margin-bottom: $space-30;
    color: $color-white !important;
  }

  #onetrust-consent-sdk {
    * {
      -webkit-font-smoothing: antialiased !important;
    }

    #accept-recommended-btn-handler,
    #onetrust-pc-sdk .ot-pc-footer .ot-pc-refuse-all-handler,
    #onetrust-pc-sdk .ot-pc-footer .save-preference-btn-handler,
    #onetrust-pc-sdk .ot-pc-footer .onetrust-close-btn-handler {
      @extend %button-override;
    }

    #onetrust-pc-sdk .ot-pc-footer .ot-pc-refuse-all-handler {
      @extend %button-with-no-background;
    }

    .ot-pc-logo {
      display: none !important;
    }

    #close-pc-btn-handler {
      outline: none !important;
    }

    #ot-pc-content {
      #ot-category-title,
      #ot-pc-title {
        font-size: 23px !important;
        line-height: 36px !important;
        color: $color-greyPlus1 !important;
        letter-spacing: -0.015em !important;
      }

      #ot-pc-desc {
        font-size: 16px;
        line-height: 26px !important;
        color: $color-grey !important;
      }

      .ot-acc-grpdesc,
      .ot-category-desc {
        font-size: 14px !important;
        line-height: 22px !important;
        color: $color-grey !important;
      }
    }
  }

  #onetrust-pc-sdk {
    border-radius: 10px !important;
  }

  // Banner
  #onetrust-banner-sdk {
    padding: 0 $space-15;
    background: $color-white !important;
    border: 1px solid $color-greyMinus2 !important;
    box-shadow: none !important;

    &:focus {
      outline: none;
    }

    #onetrust-policy-text {
      margin-bottom: 0 !important;
      font-size: 14px !important;
      line-height: 22px !important;
      color: $color-grey !important;
    }

    #onetrust-reject-all-handler,
    #onetrust-accept-btn-handler,
    #onetrust-pc-btn-handler {
      @extend %button-override;
    }

    #onetrust-reject-all-handler,
    #onetrust-pc-btn-handler {
      @extend %button-with-no-background;
    }

    .ot-sdk-row {
      display: grid;
      grid-gap: $space-8;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 1.25rem !important;
    }

    .ot-sdk-container {
      width: 100% !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding: 0 !important;
    }

    #onetrust-group-container {
      width: auto !important;
    }

    #onetrust-button-group-parent {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      transform: none !important;

      display: flex;

      width: 100% !important;
      margin-left: 0 !important;
      padding: 0 !important;

      * {
        margin: auto !important;
      }
    }

    #onetrust-button-group {
      display: flex;
    }

    #onetrust-reject-all-handler {
      margin-right: $space-8 !important;
    }

    #onetrust-policy {
      margin-top: $space-15 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .onetrust-close-btn-ui {
      top: 10px !important;
      right: 10px !important;
    }

    #onetrust-close-btn-container {
      display: none !important;
    }

    &.otFloatingRounded {
      #onetrust-button-group {
        flex-direction: column !important;

        .onetrust-banner-options {
          max-width: 100% !important;
        }
      }
    }

    @media (width <= 1000px) {
      .ot-sdk-row {
        grid-template-columns: 1fr;
      }

      #onetrust-button-group {
        flex-direction: column;
        width: 100% !important;

        button {
          width: 100% !important;

          &:not(:last-child) {
            margin-bottom: $space-8 !important;
          }
        }
      }
    }
  }

  // cookie table
  #ot-sdk-cookie-policy {
    * {
      color: $color-midnight !important;
    }

    .ot-sdk-cookie-policy-group {
      font-size: 23px !important;
      font-weight: 600 !important;
      -webkit-font-smoothing: antialiased !important;
    }

    .ot-sdk-cookie-policy-group-desc {
      font-size: 18px !important;
      font-weight: 500 !important;
      -webkit-font-smoothing: antialiased !important;
      line-height: 28px !important;
    }

    #ot-sdk-cookie-policy-v2 {
      width: 100% !important;
      padding: 0 !important;

      @each $key, $number in $numberMap {
        section:nth-of-type(#{$key}) {
          .ot-sdk-cookie-policy-group::before {
            content: "#{$number} ";
          }
        }
      }
    }

    table {
      * {
        font-size: 14px !important;
        line-height: 22px !important;
      }
    }
  }
}
