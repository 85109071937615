.clickable {
  cursor: pointer;
  text-decoration: none;
  transition: filter 0.25s ease;

  &:hover,
  &:focus {
    filter: brightness(0.7);
  }
}
