@import "/styles/variables";

%data-effect-sparkle {
  position: relative;
  z-index: 0;

  .sparkle {
    position: absolute;
    z-index: -1;

    width: 8px;
    height: 8px;

    opacity: 0;
    background: $color-sunshine;
    clip-path: polygon(50% 0%, 61% 35%, 100% 50%, 61% 65%, 50% 100%, 39% 65%, 0% 50%, 39% 35%);

    animation: sparkle 1.5s infinite ease-in-out;
  }

  /* Positioning the sparkles */
  .sparkle-1 {
    top: 30%;
    left: 5%;

    width: 12px;
    height: 12px;

    background: #ffdb52;

    animation-delay: 0s;
  }

  .sparkle-2 {
    top: 20%;
    left: 30%;

    width: 8px;
    height: 8px;

    background: rgb(255 219 82 / 60%);

    animation-delay: 0.3s;
  }

  .sparkle-3 {
    top: 70%;
    left: 50%;

    width: 10px;
    height: 10px;

    background: #ffdb52;

    animation-delay: 1s;
  }

  .sparkle-4 {
    top: 10%;
    left: 70%;

    width: 13px;
    height: 13px;

    background: #ffdb52;

    animation-delay: 1.2s;
  }

  .sparkle-5 {
    top: 70%;
    left: 30%;

    width: 13px;
    height: 13px;

    background: rgb(255 219 82 / 80%);

    animation-delay: 0.7s;
  }
}

%data-effect-sparkle-icon {
  .sparkle-5,
  .sparkle-2 {
    display: none;
  }
}

@keyframes sparkle {
  0% {
    transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }

  50% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }

  100% {
    transform: scale(0.5) rotate(90deg);
    opacity: 0;
  }
}
