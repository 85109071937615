@import "/styles/variables";

@layer layout {
  .flex {
    display: flex;
  }

  .inline {
    display: inline-flex;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-direction-row {
    flex-direction: row;
  }

  .flex-direction-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-direction-row-reverse {
    flex-direction: row-reverse;
  }

  @each $breakpoint in $media-ordered-sizes {
    @include media($breakpoint) {
      .flex-direction-column-#{$breakpoint} {
        flex-direction: column;
      }

      .flex-direction-row-#{$breakpoint} {
        flex-direction: row;
      }

      .flex-direction-column-reverse-#{$breakpoint} {
        flex-direction: column-reverse;
      }

      .flex-direction-row-reverse-#{$breakpoint} {
        flex-direction: row-reverse;
      }
    }
  }
}
