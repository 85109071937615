@import "/styles/variables";
@import "./DomEffectsSparkle.module";

.effectsProviderContainer {
  [data-effect-type~="text-glow"] {
    animation: textGlow 1.5s infinite;
  }

  [data-effect-type~="radial-glow"] {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: radial-gradient(
        circle at center,
        rgb(255 223 0 / 60%) 0%,
        rgb(255 223 0 / 20%) 30%,
        rgb(255 223 0 / 0%) 60%
      );

      animation: radialGlow 1.5s infinite ease-in-out;
    }
  }

  [data-effect-type~="sparkle"] {
    @extend %data-effect-sparkle;
  }

  [data-effect-type~="sparkle-icon"] {
    @extend %data-effect-sparkle;
    @extend %data-effect-sparkle-icon;
  }

  [data-effect-type~="hide"] {
    display: none !important;
  }

  [data-effect-type~="show"] {
    display: initial !important;
  }

  [data-effect-type="new-icon-text"],
  [data-effect-type="new-icon"] {
    position: relative;

    // is a red new icon in a star
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='36' height='18' viewBox='0 0 36 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.384766' width='35' height='18' rx='9' fill='%23ECECFE'/%3E%3Cpath d='M8.01953 13H6.65234V5.86133H8.26855L11.1348 10.4609L11.2617 10.4414V5.86133H12.6191V13H11.1738L8.1416 8.08789L8.01953 8.10742V13ZM16.3955 9.86523V11.8184H19.8037V13H14.9844V5.86133H19.7256V7.04297H16.3955V8.71289H19.3057V9.86523H16.3955ZM24.1074 13H22.5303L20.8945 5.86133H22.3008L23.3799 10.8809H23.502L24.7812 5.86133H25.9873L27.291 10.8809H27.4131L28.4971 5.86133H29.8545L28.2236 13H26.6416L25.3916 8.39551H25.2891L24.1074 13Z' fill='%23A2A2FC'/%3E%3C/svg%3E%0A");

      position: absolute;
      z-index: 1;
      top: -2px;
      right: -6px;
      @include media("mobile") {
        top: -8px;
      }
    }
  }

  [data-effect-type="new-icon-text"] {
    position: relative;

    // is a purple new icon. localisation not required so far.
    &::after {
      position: static;
      align-self: center;
      margin-top: 6px;
      margin-left: 8px;
    }
  }
}

// add on any element with text. E.g. tabs, links, Text
@keyframes textGlow {
  100%,
  0% {
    text-shadow:
      0 -3px 12px rgb(255 219 82 / 40%),
      0 3px 12px rgb(255 219 82 / 40%);
  }

  50% {
    text-shadow:
      0 -6px 12px $color-sunshine,
      0 6px 12px $color-sunshine;
  }
}

// add on square aspect ratio elements. e.g. icons, avatars
@keyframes radialGlow {
  0% {
    transform: scale(0.85);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(0.85);
  }
}
