@import "/styles/variables";

$border: 1px solid rgba($color-white, 0.2);

.bannersContainer {
  position: fixed;
  z-index: $z-index-switch-locale-banner + 1;
  top: 0;
  left: 0;

  width: 100%;

  div:not(:last-child, :empty) {
    border-bottom: $border;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.ReactModal__Body--open) & {
    border-bottom: $border;
  }
}
