@use "sass:color";

@import "../constants";

.pointer {
  position: absolute;
  z-index: 1;
  top: -#{$pointer-height};
  right: auto;
  left: auto;

  width: $pointer-width-equilateral;
  height: $pointer-height;

  &.rightAngle {
    width: $pointer-width-right-angle;
  }

  &.side {
    width: $pointer-height;
    height: $pointer-width-equilateral;

    &.rightAngle {
      height: $pointer-width-right-angle;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    fill: $color-white;
    stroke: $color-greyMinus2;

    // Super hacky way of not including a border on the bottom side of the pointer so it looks like part of the dropdown. Will only work for the ./EquilateralPointer.svg file - if we add a new one we will also need to create a custom dash array for it
    stroke-dasharray: 0, 3, 29, 36;
    stroke-width: 1px;
  }

  &.black {
    svg {
      fill: $color-black;
      stroke: none;
    }
  }

  &.red {
    svg {
      fill: $color-red;
      stroke: none;
    }
  }
}

.invisibleHoverEdge {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1.1);
}

.dropdownContent {
  cursor: default;

  z-index: 0;

  overflow: auto;

  min-width: 133px;
  max-height: calc(100vh - #{$navbar-height});
  padding: 7px;

  text-shadow: none;

  background: $color-white;
  border: $border;
  border-radius: 16px;
  box-shadow: 0 13px 26px color.change($color-black, $alpha: 0.08);

  &.noBottomPadding {
    padding-bottom: 0;
  }

  &.black {
    color: $color-white;
    background: $color-black;
    border: 0;
  }

  &.red {
    color: $color-white;
    background: $color-red;
    border: 0;
  }

  &.noMinWidth {
    min-width: 0;
  }
}
