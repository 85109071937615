@import "/styles/variables";

@layer utilities {
  /**
  * Colors that match up to the UI kit
  */
  .color-greyMinus3F7F7F7 {
    fill: $color-greyMinus3;
  }

  .color-greyMinus2DFDFE0 {
    fill: $color-greyMinus2;
  }

  .color-greyMinus1BFBFBF {
    fill: $color-greyMinus1;
  }

  .color-grey7F7F7F {
    fill: $color-grey;
  }

  .color-grey747474 {
    fill: $color-greyscale-800;
  }

  .color-greyPlus14A4A4A {
    fill: $color-greyPlus1;
  }

  .color-midnight2C343E {
    fill: $color-midnight;
  }

  .color-black000000 {
    fill: $color-black;
  }

  .color-whiteFFFFFF {
    fill: $color-white;
  }

  .color-green05A081 {
    fill: $color-green;
  }

  .color-redD3405C {
    fill: $color-red;
  }

  .color-yellowFFC864 {
    fill: $color-yellow;
  }

  .color-alien {
    fill: $color-alien;
  }

  .color-jellyfish {
    fill: $color-jellyfish;
  }

  .color-papaya {
    fill: $color-papaya;
  }

  .color-strawberry {
    fill: $color-strawberry;
  }

  .color-sunshine {
    fill: $color-sunshine;
  }

  @include generate-tokencolor-classes("fill");

  .rotate {
    transform: rotate(180deg);
  }

  .rotateHorizontal {
    transform: rotateY(180deg);
  }
}
