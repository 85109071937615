body.signedIn {
  .hideWhenSignedIn {
    display: none;
  }
}

body:not(.signedIn) {
  .hideWhenSignedOut {
    display: none;
  }
}
