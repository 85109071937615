@import "/styles/variables";
@import "/shared/ui/Modal/Modal.module";

.video {
  --modal-x-padding: #{-$space-30};
  @include media("mobile", "tablet") {
    --modal-x-padding: #{-$space-24};
  }

  overflow: hidden;

  width: calc(100% + var(--modal-x-padding) * -2);
  margin: -$space-24 var(--modal-x-padding) $space-24;

  line-height: 0;

  background-color: #efefef; // matches video's chosen background
  border-radius: $modal-border-radius $modal-border-radius 0 0;

  video {
    width: 100%;
    height: 100%;
    max-height: 400px;
  }

  &.phoneFrame {
    padding-top: $space-30;
    text-align: center;
    background-color: $color-white;

    video {
      width: auto;
      border: 5px solid $color-black;
      border-radius: 20px;
    }
  }
}
