@import "/styles/variables";

$bounce: cubic-bezier(0.175, 0.885, 0.32, 2);

$badge-size-desktop: 45px;
$badge-size-mobile: 28px;
$icon-size-desktop: 24px;
$icon-size-mobile: 16px;

$badge-size-small-desktop: 26px;
$badge-size-small-mobile: 26px;
$icon-size-small-desktop: 18px;
$icon-size-small-mobile: 18px;

@mixin theme($color) {
  .text {
    color: $color;
  }

  svg {
    fill: $color;
  }
}

@mixin sizing($size: null) {
  $desktop-size: if($size == "small", $badge-size-small-desktop, $badge-size-desktop);
  $mobile-size: if($size == "small", $badge-size-small-mobile, $badge-size-mobile);
  $icon-desktop-size: if($size == "small", $icon-size-small-desktop, $icon-size-desktop);
  $icon-mobile-size: if($size == "small", $icon-size-small-mobile, $icon-size-mobile);

  @include media("desktop", "oversized") {
    min-width: $desktop-size;
    height: $desktop-size;
  }

  min-width: $mobile-size;
  height: $mobile-size;

  svg {
    @include media("desktop", "oversized") {
      width: $icon-desktop-size;
      height: $icon-desktop-size;
    }

    width: $icon-mobile-size;
    height: $icon-mobile-size;
  }

  @if $size == "small" {
    padding: $space-4;

    .text {
      font-size: 11px;
    }
  } @else {
    padding: 6px;

    .text {
      @include media("mobile", "tablet") {
        font-size: 12px;
      }
    }
  }
}

@mixin expanded($size: null) {
  .textContainer {
    grid-template-columns: 1fr;
  }

  .text {
    @if $size != "small" {
      @include media("desktop", "oversized") {
        margin-left: $space-8;
      }
    }

    transform: translateX(0);
    margin-left: $space-4;
  }
}

.badge {
  @include sizing;

  @include theme($color-white);

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50px;

  &.white {
    @include theme(#454545);

    background-color: rgba($color-white, 0.64);
    backdrop-filter: blur(3px);
  }

  &.small {
    @include sizing("small");
  }

  &.hasText {
    @include media("desktop", "oversized") {
      &.hideTextUntilHovered {
        &:hover {
          @include expanded;

          &.small {
            @include expanded("small");
          }
        }
      }
    }

    @include media("mobile", "tablet") {
      &.expandOnMobile {
        @include expanded;

        &.small {
          @include expanded("small");
        }
      }
    }

    &:not(.hideTextUntilHovered) {
      @include expanded;

      &.small {
        @include expanded("small");
      }
    }
  }

  &.pulse svg {
    animation-name: loading;
    animation-duration: 400ms;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.textContainer {
  display: grid;
  grid-template-columns: 0fr;
  white-space: nowrap;
  transition: grid-template-columns 1s $bounce;
}

.text {
  transform: translateX(20px);
  overflow: hidden;
  transition:
    margin 0.6s $bounce,
    transform 0.2s ease;
}

@keyframes loading {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.newBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  margin-left: -2px; // to add visual balance on the left
  padding: $space-8 $space-12;

  color: $color-jellyfish-dark;

  background-color: $color-jellyfish-lighter;
  border-radius: 50px;

  span {
    line-height: 0;
  }

  &.size-small {
    height: 24px;
  }
}
