// The scss code here is not scoped and will be loaded on every page.
// Prefer the use of css modules over adding new styles here.

@use "sass:color";

@layer base, mui, spacing, layout, utilities;

@import "google-places-autocomplete";

@layer base {
  @import "/styles/variables/index";
  @import "normalize";
  @import "canvaSans";

  // @import "braze";
  @import "nprogress";
  @import "onetouch";
  @import "carousel";

  @import "layout";
  @import "react-responsive-carousel/lib/styles/carousel.min";

  body,
  html {
    font-family: $font-family-body;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-heading;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  textarea,
  input[type="text"] {
    appearance: none;
  }

  .is-hidden {
    visibility: hidden !important;
  }
}
