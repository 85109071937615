@import "/styles/variables";

// Make sure the avatar is always the desired size in any situation
@mixin overkill-assure-size($size) {
  width: $size;
  min-width: $size;
  max-width: $size;
  height: $size;
  min-height: $size;
  max-height: $size;
}

.avatar {
  position: relative;
  background: $color-white;

  &,
  > img {
    border-radius: 50%;
  }

  > img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.heroBadgeContainer {
  position: absolute;
  right: 0;
  bottom: 0;
}

.size-130 {
  @include overkill-assure-size(130px);
}

.size-80 {
  @include overkill-assure-size(80px);
}

.size-70 {
  @include overkill-assure-size(70px);
}

.size-50 {
  @include overkill-assure-size(50px);
}

.size-40 {
  @include overkill-assure-size(40px);
}

.size-30 {
  @include overkill-assure-size(30px);
}

.size-40,
.size-50,
.size-30 {
  .heroBadgeContainer {
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 16px;
  }
}

.border {
  border: 2px solid white;
}
