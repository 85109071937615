@import "variables";

.banner {
  display: flex;
  gap: $space-8;
  align-items: center;

  color: $color-white;
  text-align: center;
  @include media("mobile") {
    text-align: left;
  }

  > p {
    flex: 1;
  }

  // On mobile, do not show both the message and cookie banners at the same time
  &.withCookieBanner {
    @include media("mobile") {
      display: none;
    }
  }
}

.learnMore {
  height: initial;

  // Tone down default button padding
  padding: 9px 16px;

  // Match main banner text typography
  font-size: 14px;
  font-weight: 500;
}
