@import "../constants";

.dropdown {
  position: absolute;
  z-index: $z-index-dropdowns;
  top: calc(100% + #{$distance-from-top});
  display: none;

  &.open {
    display: block;
  }
}
